import { reqHarmTypes } from "@/api";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    harmTypes: [],
    token: sessionStorage.getItem("token") ?? "",
    userInfo: sessionStorage.getItem("userInfo") ?? ""
  },
  getters: {
    hasToken: state => !!state.token
  },
  mutations: {
    SET_HARMTYPES(state, payload) {
      state.harmTypes = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
      sessionStorage.setItem("token", payload);
    },
    SET_USER_INFO(state, payload) {
      state.userInfo = payload;
      sessionStorage.setItem("userInfo", payload);
    }
  },
  actions: {
    async initHarmTypes(ctx) {
      try {
        const { data } = await reqHarmTypes();
        ctx.commit("SET_HARMTYPES", data);
      } catch (error) {
        //
      }
    }
  },
  modules: {}
});
