export enum NewsAction {
  INFORMATION,
  STANDARD,
  RUMOURS,
  ANNOUNCEMENTS,
  WARNING,
  /**
   * 网信说法
   */
  CLAIM,
  REPORTACCEPT,
  COMPREHENSIVECONTROL,
  ANTITRIADPUTTING,
  COMPREHENSIVEANTICHEAT
}

// 开发环境栏目id
// export enum NewsColumns {
//   //网信信息
//   INFORMATION = "10908326",
//   //政策法规
//   STANDARD = "10908327",
//   // 法律
//   LAW = "10908418",
//   // 行政法规
//   ADMINISTRATIVEREGULATIONS = "10908419",
//   // 部门规章
//   DEPARTMENTALREGULATIONS = "10908420",
//   // 司法解释
//   JUDICIALINTERPRETATION = "10908421",
//   // 规范性文件
//   NORMATIVEDOCUMENTS = "10908422",
//   // 政策文件
//   POLICYDOCUMENT = "10908423",
//   // 辟谣专区
//   RUMOURS = "10908328",
//   //通知公告
//   ANNOUNCEMENTS = "10908329",
//   //安全警示
//   WARNING = "10908330",
//   //网信说法
//   CLAIM = "10908331",
//   //举报受理情况
//   REPORTACCEPT = "10908341",

//   //综合治理
//   COMPREHENSIVECONTROL = "10908342",

//   //扫黑除恶
//   ANTITRIADPUTTING = "10908344",
//   // 扫黑除恶视频子类
//   COMPREHENSIVEANTICHEATVIDEO = "10908354",

//   //全民反诈
//   COMPREHENSIVEANTICHEAT = "10908345",

//   //新增专题类
//   // 重庆辟谣
//   CQRUMOURS = "10908357",
//   // 川渝辟谣
//   CYRUMOURS = "10908339",
//   // 历史虚无主义
//   HISTORY = "10908358",
//   // 非法收集个人信息
//   PERSONALINFOR = "10908370",
//   // 非法收集个人信息相关政策
//   PERSONALINFORPOLICY = "10908371",
//   // 清朗巴渝系列专项举报
//   BAANDYU = "10908471",
//   // 自媒体专项举报
//   WEMEDIA = "10908472"
// }

// 正式环境栏目id;
export enum NewsColumns {
  //网信信息
  INFORMATION = "1806233",

  //政策法规
  STANDARD = "1806235",
  // 法律
  LAW = "1806661",
  // 行政法规
  ADMINISTRATIVEREGULATIONS = "1806662",
  // 部门规章
  DEPARTMENTALREGULATIONS = "1806663",
  // 司法解释
  JUDICIALINTERPRETATION = "1806664",
  // 规范性文件
  NORMATIVEDOCUMENTS = "1806665",
  // 政策文件
  POLICYDOCUMENT = "1806666",

  //辟谣专区
  RUMOURS = "1806537",

  //通知公告
  ANNOUNCEMENTS = "1806237",
  //安全警示
  WARNING = "1806239",
  //网信说法
  CLAIM = "1806549",
  //举报受理情况
  REPORTACCEPT = "1806267",
  //综合治理
  COMPREHENSIVECONTROL = "1806535",
  //机构简介
  INSTITUTIONALPROFILE = "1806241",
  //各地方举报渠道
  REPORTINGCHANNELS = "1806557",
  //举报指南
  REPORTINGGUIDELINES = "1806553",
  //扫黑除恶
  ANTITRIADPUTTING = "1806533",
  // 扫黑除恶视频子类
  COMPREHENSIVEANTICHEATVIDEO = "1806539",
  //全民反诈
  COMPREHENSIVEANTICHEAT = "1806531",

  //新增专题类
  // 重庆辟谣
  CQRUMOURS = "1806547",
  // 打击整治养老诈骗专项行动
  PENSION = "1810140",
  // 川渝辟谣
  CYRUMOURS = "1806541",
  // 历史虚无主义
  HISTORY = "1806545",
  // 非法收集个人信息
  PERSONALINFOR = "1806265",
  // 非法收集个人信息相关政策
  PERSONALINFORPOLICY = "1806543",
  // 清朗巴渝系列专项举报
  BAANDYU = "1806738",
  // 自媒体专项举报
  WEMEDIA = "1806739"
}

export enum PoliticCountenance {
  群众 = "群众",
  共青团员 = "共青团员",
  中共党员 = "中共党员",
  中共预备党员 = "中共预备党员",
  无党派 = "无党派",
  中国国民党革命委员会 = "中国国民党革命委员会",
  中国民主同盟 = "中国民主同盟",
  中国民主建国会 = "中国民主建国会",
  中国民主促进会 = "中国民主促进会",
  中国农工民主党 = "中国农工民主党",
  中国致公党 = "中国致公党",
  九三学社 = "九三学社",
  台湾民主自治同盟 = "台湾民主自治同盟"
}

export enum Nation {
  汉族 = "汉族",
  壮族 = "壮族",
  满族 = "满族",
  回族 = "回族",
  苗族 = "苗族",
  维吾尔族 = "维吾尔族",
  土家族 = "土家族",
  彝族 = "彝族",
  蒙古族 = "蒙古族",
  藏族 = "藏族",
  布依族 = "布依族",
  侗族 = "侗族",
  瑶族 = "瑶族",
  朝鲜族 = "朝鲜族",
  白族 = "白族",
  哈尼族 = "哈尼族",
  哈萨克族 = "哈萨克族",
  黎族 = "黎族",
  傣族 = "傣族",
  畲族 = "畲族",
  傈僳族 = "傈僳族",
  仡佬族 = "仡佬族",
  东乡族 = "东乡族",
  高山族 = "高山族",
  拉祜族 = "拉祜族",
  水族 = "水族",
  佤族 = "佤族",
  纳西族 = "纳西族",
  羌族 = "羌族",
  土族 = "土族",
  仫佬族 = "仫佬族",
  锡伯族 = "锡伯族",
  柯尔克孜族 = "柯尔克孜族",
  达斡尔族 = "达斡尔族",
  景颇族 = "景颇族",
  毛南族 = "毛南族",
  撒拉族 = "撒拉族",
  布朗族 = "布朗族",
  塔吉克族 = "塔吉克族",
  阿昌族 = "阿昌族",
  普米族 = "普米族",
  鄂温克族 = "鄂温克族",
  怒族 = "怒族",
  京族 = "京族",
  基诺族 = "基诺族",
  德昂族 = "德昂族",
  保安族 = "保安族",
  俄罗斯族 = "俄罗斯族",
  裕固族 = "裕固族",
  乌孜别克族 = "乌孜别克族",
  门巴族 = "门巴族",
  鄂伦春族 = "鄂伦春族",
  独龙族 = "独龙族",
  塔塔尔族 = "塔塔尔族",
  赫哲族 = "赫哲族",
  珞巴族 = "珞巴族"
}

export enum Education {
  初中及以下 = "初中及以下",
  "高中/中专" = "高中/中专",
  大专 = "大专",
  本科 = "本科",
  硕士研究生 = "硕士研究生",
  博士研究生 = "博士研究生"
}
