import Axios from "@/plugins/axios";
import { date2Format } from "@/utils/index";
/**
 * 通用返回结构
 */
export interface TIResponse {
  code: number;
  message: string;
  sessionId: string;
}

/** 唤醒APP信息 */
export interface TIShareInfo {
  /** 顶图	 */
  shareBg: string;
  /** 分享图标	 */
  shareLogo: string;
  /** 客户端名字	 */
  appName: string;
  /** 客户端下载地址	 */
  downloadUrl: string;
  /** 底图 */
  shareClose: string;
  /** scheme */
  schemeUrl: string;
}
export interface TIWXResponse extends TIResponse {
  data: string;
}
export interface TIWXConfig {
  /** 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。 */
  debug: boolean;
  /** 公众号的唯一标识 */
  appId: string;
  /** 生成签名的时间戳 */
  timestamp: number;
  /** 生成签名的随机串 */
  nonceStr: string;
  /**签名*/
  signature: string;
  /** Js */
  jsApiList: string[];
  openTagList?: string[];
}
export enum ReportType {
  ANONYMOUS_REPORT = "匿名举报",
  REAL_NAME_REPORT = "实名举报",
  REGISTRATION_REPORT = "注册举报"
}

export type FormRules<T = Record<string, string>> = {
  [P in keyof T]?: {
    trigger?: "blur" | "change";
    required?: boolean;
    message?: string;
    validator?: (
      rule: any,
      value: any,
      callback: (data?: any) => void
    ) => unknown;
  }[];
};

/**
 * 新增举报单
 */
export type VoReportingNew = {
  address?: string;
  appendixList?: VoFileUploaded[];
  city?: string;
  content?: string;
  phoneVerificationCode?: string;
  contentNumber?: string;
  county?: string;
  email?: string;
  employmentCategory?: string;
  harmTypeId?: string;
  indexNumber?: string;
  personOrLegalPerson?: string;
  phoneNumber?: string;
  province?: string;
  reportedAgencyKindId?: string;
  reportingWay?: keyof typeof ReportType;
  sex?: string;
  siteUrlApp?: string;
  trueName?: string;
  unitCategory?: string;
  unitName?: string;
  validateCode?: string;
  validateCode0?: string;
  reportObj?: string;
  countyObj?: string;
};

/**
 * 网络监督举报志愿者报名表单列表
 */
export type VoNetworkInformerSign = {
  name?: string;
  sex?: string;
  validateCode?: string;
  linkPhone?: string;
  province?: string;
  county?: string;
  city?: string;
  workUnit?: string;
  birthday?: any;
  politicalStatus?: string;
  nation?: string;
  education?: string;
  district?: string[];
};

/**
 * 危害类型列表
 */
export type VoReportHarmTypes = {
  id: string;
  harmName: string;
  children?: VoReportHarmTypes[];
};

/**
 * 被举报平台列表
 */
export type VoAgencyTypes = {
  label?: string;
  children?: VoAgencyTypes[];
};

/**
 * 上传文件的返回
 */
export type VoFileUploaded = {
  bucketName: string;
  fileExt: string;
  fileName: string;
  fileUrl: string;
  originalFileName: string;
};

/**
 * 保存举报单
 */
export type DtoReportingForm = VoReportingNew;

/**
 * 网络监督举报志愿者报名表单列表
 */
export type DtoNetworkInformerSign = VoNetworkInformerSign;

/**
 * 用户注册实体
 */
export interface DtoUserRegister {
  address: string;
  city: string;
  county: string;
  email: string;
  password: string;
  phoneNumber: string;
  phoneVerificationCode: string;
  province: string;
  sex: string;
  trueName: string;
  userName: string;
  userType: string;
}

/**
 * 用户注册
 * @param data
 * @returns
 */
export function reqRegisterUser(data: DtoUserRegister) {
  return Axios.post(`/front/user/post/save`, data);
}

/**
 * 忘记密码实体
 */
export interface DtoUserForget {
  emailValidateCode: string;
  password: string;
  userName: string;
  validateCode: string;
  phoneValidateCode: string;
}

/**
 * 用户注册
 * @param data
 * @returns
 */
export function forgetPass(data: DtoUserForget) {
  return Axios.post(`/front/user/post/forgot`, data);
}

/**
 * 发送验证码
 * @param phoneNumber
 * @returns
 */
export function smsSend(phoneNumber: string) {
  return Axios.post(`/front/sms/send/${phoneNumber}`);
}

/**
 * 实名认证界面，发送短信验证码
 * @param phoneNumber    手机号
 * @param validateCode0  图形验证码
 * @returns
 */
export function smsSend2(phoneNumber: string, validateCode0: string) {
  return Axios.post(`/front/sms/send2/${phoneNumber}/${validateCode0}`);
}

export interface UserForgetSmsDto {
  userName: string;
  validateCode: string;
}

/**
 * 忘记密码手机验证码
 * @param data
 * @returns
 */
export function forgetSmsSend(data: UserForgetSmsDto) {
  return Axios.post(`/front/sms/forget/send`, data);
}

/**
 * 用户修改信息实体
 */
export interface DtoUserUpdate {
  address: string;
  city: string;
  county: string;
  email: string;
  id: string;
  phoneNumber: string;
  province: string;
  sex: string;
  trueName: string;
  userName: string;
}

/**
 * 修改用户信息
 * @param data
 * @returns
 */
export function updateUser(data: DtoUserUpdate) {
  return Axios.post(`/front/user/post/update`, data);
}

/**
 * 用户登录实体
 */
export interface DtoUserLogin {
  password0: string;
  userName0: string;
  validateCode0: string;
}
/**
 * 注销账号
 * @returns
 */
export function cancellationAccount() {
  return Axios.post(`/front/user/post/cancellation`);
}

/**
 * 生成登录验证码
 * @returns
 */
export function reqValidateCode() {
  return Axios.get<string>(`/front/user/ValidateCode/generate`);
}

/**
 * 用户登录
 * @param data
 * @returns
 */
export function reqUserLogin(data: DtoUserLogin) {
  return Axios.post(`/front/user/post/login`, data);
}

/**
 * 用户信息Vo
 */
export type VoUserInfo = {
  address: string;
  city: string;
  county: string;
  email: string;
  id: string;
  phoneNumber: string;
  province: string;
  sex: string;
  trueName: string;
  userName: string;
};

/**
 * 获取用户信息
 * @returns
 */
export function reqUserInfo() {
  return Axios.get<VoUserInfo>(`/front/user/post/info`);
}

/**
 * 获取危害类型
 * @returns
 */
export function reqHarmTypes() {
  return Axios.get<VoReportHarmTypes[]>(`/front/report/get/harmTypes`);
}

/**
 * 获取被举报平台
 * @returns
 */
export function reqAgencyKinds() {
  return Axios.get<VoAgencyTypes[]>(`/front/report/get/reportedAgencyKinds`);
}

/**
 * 新增举报单 - 用于生成一些基本信息
 * @returns
 */
export function reqNewReporting() {
  return Axios.get(`/front/report/post/new`);
}

/**
 * 刷新举报页面的验证码
 * @param indexNumber
 * @returns
 */
export function reqValidateCodeReporting(indexNumber: string) {
  return Axios.get<string>(`/front/report/ValidateCode/generate`, {
    params: {
      indexNumber
    }
  });
}

/**
 * 获取实名认证的图形验证码
 */
export function reqValidateCodeReportingPic() {
  return Axios.get<string>(`/front/RealNameReport/ValidateCode/generate`);
}

/**
 * 保存举报单
 * @param data
 * @returns
 */
export function reqReportingSaveHandler(data: DtoReportingForm) {
  return Axios.post<string>(`/front/report/post/save`, data);
}

/**
 * 文章列表
 * @param id
 * @returns
 */
export function reqArticleList(id: string) {
  return Axios.get<string>(
    `/web/column/col${id}.js?t=${new Date().getTime()}`,
    {
      baseURL: "https://www.cqjbzx.cn/tes/"
      // baseURL: process.env.VUE_APP_TES_BASE
    }
  );
}

/**
 * 举报单查询
 * @param indexNumber
 * @param validateCode
 * @returns
 */
export function reqReportQuery(indexNumber: string, validateCode: string) {
  return Axios.get(`/front/report/query/${indexNumber}/${validateCode}`);
}

/**
 * 地域列表返回体
 */
export type VoAreaList = {
  code: string;
  name: string;
  pcode: string;
};

/**
 * 获取地域
 * @param code
 * @returns
 */
export function reqArea(code = "0") {
  return Axios.get<VoAreaList[]>(`/front/area/query/${code}`);
}

/**
 * 从业类别
 */
export enum EmploymentCategory {
  COMPANY = "企业人员",
  ENTERPRISE = "事业单位人员",
  OFFICIAL = "公务员",
  STUDENT = "学生",
  FREELANCE = "自由职业者",
  OTHER = "其他"
}

/**
 * 新闻返回体
 */
export type VoNewsList = {
  title: string;
  content: string;
  detailUrl: string;
  origin: string;
  publishTime: string;
};

// 网络监督举报志愿者 --start

/**
 * 获取验证码接口
 * @returns
 */
export function reqValidateCodeSign() {
  return Axios.get(`/front/volu/ValidateCode/generate`);
}

/**
 * 提交
 * @returns
 */
export function reqNetworkInformerSignSubmit(data: DtoNetworkInformerSign) {
  const payload = {
    ...data,
    district: data.district ? data.district[0] : "",
    birthday: date2Format(data.birthday, "yyyy-MM-dd HH:mm:ss")
  };
  return Axios.post(`/front/volu/submit`, payload);
}

// 网络监督举报志愿者 --end
