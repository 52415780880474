/**
 * 转换拓尔思接口文章列表
 * @param data
 * @returns
 */
export function transformNewsString(data: string) {
  try {
    while (data.match(/&&(.*)&&/)) {
      data = data.replace(RegExp.lastMatch, RegExp.$1.replace(/"/g, `'`));
    }
    // content字段，临时处理下错误转义字符
    data = data.replace(/\\(?!")/g, "\\\\");
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
}

/**
 * 去除字段串开头结尾处的若干个&
 * @param str
 */
export function trimAmpersands(str: string) {
  while (str.startsWith("&")) {
    str = str.slice(1);
  }

  while (str.endsWith("&")) {
    str = str.slice(0, -1);
  }

  return str;
}
/**
 * 判断是否为json字符串
 * @param str
 */
export function isJsonString(str: string): boolean {
  try {
    const result = JSON.parse(str);
    return typeof result === "object" && result !== null;
  } catch (e) {
    return false;
  }
}
/**
 * 阅读文章
 * @param item
 */
export function readNewsDetailHandler(item: any) {
  sessionStorage.setItem("news-detail", JSON.stringify(item));
  window.open(item.detailUrl);
}

/**
 * date转string yyyy-MM-dd HH:mm:ss
 */

export function date2Format(date: Date, fmt: string) {
  const o: any = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "H+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (const k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
}
