import Vue from "vue";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Form,
  FormItem,
  Input,
  Image,
  Row,
  Link,
  Radio,
  RadioGroup,
  Select,
  Option,
  Upload,
  Loading,
  Message,
  Pagination,
  Cascader,
  DatePicker
} from "element-ui";

import "./element.scss";

Vue.use(Cascader);
Vue.use(Pagination);
Vue.use(Loading);
Vue.use(Upload);
Vue.use(Select);
Vue.use(Option);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Link);
Vue.use(Row);
Vue.use(Button);
Vue.use(Image);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(DatePicker);

Vue.prototype.$message = Message;
